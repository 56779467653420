import { BASE_API } from "../../../../../utils/constantes";
import {
  generarDataSetBar,
  generarDataSetBarEstados,
  generarDataSetLine,
  generarDataSetPie,
} from "../utils";

export async function getDerivacionesPorConvenio() {
  let data = await fetch(
    `${BASE_API}/api/dashboard/derivacion_convenio_anio/`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return generarDataSetBar(data);
    });
  return data;
}

export async function getDerivacionesPorAnio() {
  let data = await fetch(`${BASE_API}/api/dashboard/derivacion_anio/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return generarDataSetLine(data);
    });
  return data;
}

export async function getAniosDisponiblesDerivaciones() {
  let anios = await fetch(
    `${BASE_API}/api/dashboard/derivacion_estado_anio/?anios_disponibles=true`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data.map((item) => item.anio);
    });
  console.log(anios);
  return anios;
}

export async function getEstadoConveniosPorAnio(anio) {
  let data = await fetch(
    `${BASE_API}/api/dashboard/derivacion_estado_anio/?anio=${anio}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return generarDataSetPie(data);
    });

  return data;
}

export async function getEstadoConveniosPorMesYAnio(anio) {
  let data = await fetch(
    `${BASE_API}/api/dashboard/derivacion_estado_anio_mes/?anio=${anio}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return generarDataSetBarEstados(data);
    });
  return data;
}
