import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import {useEffect, useState} from "react";
import { MenuItem, Menu } from "semantic-ui-react";
import { getAniosDisponiblesDerivaciones, getEstadoConveniosPorAnio, getEstadoConveniosPorMesYAnio } from './serviceOrion';

ChartJS.register(ArcElement, Tooltip, Legend);

const DerivacionesOrionEstado = () => {

    const [dataSet, setDataSet] = useState(null);
    const [dataSetBar, setDataSetBar] = useState(null);
    const [activeItem, setActiveItem] = useState(new Date().getFullYear());
    const [aniosDisponibles, setAniosDisponibles] = useState([
      new Date().getFullYear(),
    ]);
    const handleItemClick = (e, { name }) => setActiveItem(name);

    useEffect(() => {
        async function fetchData() {
            setAniosDisponibles(await getAniosDisponiblesDerivaciones());
          }
          fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
          setDataSet(await getEstadoConveniosPorAnio(activeItem));
          setDataSetBar(await getEstadoConveniosPorMesYAnio(activeItem));
        }
    
        fetchData();
      }, [activeItem]);

    return dataSet && dataSetBar ? (
        <div>
          <Menu attached="top" tabular>
            {aniosDisponibles &&
              aniosDisponibles.map((anio) => (
                <MenuItem
                  name={anio}
                  active={activeItem === anio}
                  onClick={handleItemClick}
                />
              ))}
          </Menu>{" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Pie width={300} data={dataSet} />
            </div>
            <div style={{ width: "100%" }}>
              <Bar height={100} data={dataSetBar} />
            </div>
          </div>
        </div>
      ) : (
        <div>Cargando...</div>
      );
}
export default DerivacionesOrionEstado;