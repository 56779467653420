import {Line} from "react-chartjs-2";
import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip} from "chart.js";
import {useEffect, useState} from "react";
import { getServiciosPorAnio } from "./serviceLizen";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip);

const ServiciosLizenTiempo = () => {


    const [dataSet, setDataSet] = useState(null)


    useEffect(() => {
        async function fetchData() {
            setDataSet(await getServiciosPorAnio())
        }
        fetchData()
    }, [])

    const options = {
        plugins:{
            legend:true,
        },
    }

    return (dataSet? <Line data={dataSet} height={50} options={options}></Line> : <div>Cargando...</div>)
};

export default ServiciosLizenTiempo;