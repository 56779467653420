import {Bar} from "react-chartjs-2";
import {Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip, BarElement} from "chart.js";
import {useEffect, useState} from "react";
import { getServiciosPorConvenio } from "./serviceLizen";
ChartJS.register(BarElement, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip);

const ServiciosLizenConvenioBar = () => {

    const [dataSet, setDataSet] = useState(null);

    useEffect(() => {
        async function fetchData() {
            setDataSet(await getServiciosPorConvenio());
        }
        fetchData();
    }, []);

    const options = {
        plugins:{
            legend:true,
        },
    }
    return (dataSet? <Bar height={50} data={dataSet} options={options} />: <div>Cargando...</div>);
}

export default ServiciosLizenConvenioBar;