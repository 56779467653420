const CANT_MAX_ANIOS = 5;
const MESES = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Setiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
const MESES_NUM = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const BACKGROUND_COLORS = [
  "rgba(255, 99, 132, 0.2)",
  "rgba(255, 159, 64, 0.2)",
  "rgba(255, 205, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(210,0,255,0.26)",
];

export const BORDER_COLORS = [
  "rgb(255, 99, 132)",
  "rgb(255, 159, 64)",
  "rgb(255, 205, 86)",
  "rgb(75, 192, 192)",
  "rgb(54, 162, 235)",
  "rgb(153, 102, 255)",
  "rgb(201, 203, 207)",
];

// Funciones de graficos de lineas
export function generarDataSetLine(data) {
  const meses = MESES_NUM;
  const formatedData = [];
  const anios = generarListaAnios(data);

  anios.forEach((anio) => {
    meses.forEach((mes) => {
      const item = data.find((item) => item.anio === anio && item.mes === mes);
      if (item) {
        formatedData.push({ mes: mes, anio: anio, cantidad: item.cantidad });
      } else {
        formatedData.push({ mes: mes, anio: anio, cantidad: 0 });
      }
    });
  });

  return formatearDataSetLine(formatedData, anios);
}

function formatearDataSetLine(formatedData, anios) {
  const datasets = [];

  anios.forEach((anio) => {
    const data = formatedData.filter((item) => item.anio === anio);
    const dataEjeY = data.map((item) => item.cantidad);

    datasets.push({
      label: anio,
      data: dataEjeY,
      tension: 0.1,
      backgroundColor: BACKGROUND_COLORS,
      borderColor: BORDER_COLORS,
      pointBorderColor: "black",
    });
  });
  return {
    labels: MESES,
    datasets,
  };
}

// Funciones de graficos de barras
export function generarDataSetBar(data) {
  const anios = generarListaAnios(data);
  const aseguradoras = generarListaConvenios(data, anios);
  const datasets = [];

  anios.forEach((anio) => {
    const dataAnio = data.filter((item) => item.anio === anio);
    const dataEjeY = dataAnio.map((item) => item.cantidad);

    datasets.push({
      label: anio,
      data: dataEjeY,
      tension: 0.1,
      backgroundColor: BACKGROUND_COLORS,
      borderColor: BORDER_COLORS,
      pointBorderColor: "black",
    });
  });

  return {
    labels: aseguradoras,
    datasets,
  };
}

function generarListaConvenios(data, anios) {
  let aseguradoras = [];
  let dataDeUnAnio = data.filter((item) => item.anio === anios[0]);

  dataDeUnAnio.forEach((item) => {
    if (!aseguradoras.includes(item.aseguradora)) {
      aseguradoras.push(item.aseguradora);
    }
  });
  return aseguradoras;
}

export function generarDataSetBarEstados(data) {
  const estados = [];
  const datasets = [];

  data.forEach((item) => {
    if (!estados.includes(item.estado)) {
      estados.push(item.estado);
    }
  });

  let formatedData = [];

  estados.forEach((estado) => {
    let cantidadPorMes = [];
    MESES_NUM.forEach((mes) => {
      const dataMesYEstado = data.find(
        (item) => item.mes === mes && item.estado === estado
      );

      if (!dataMesYEstado) {
        cantidadPorMes.push(0);
      } else {
        cantidadPorMes.push(dataMesYEstado.cantidad);
      }
    });
    formatedData.push({ estado, cantidadPorMes });
  });

  formatedData.forEach((item, index) => {
    datasets.push({
      label: item.estado,
      data: item.cantidadPorMes,
      backgroundColor: BACKGROUND_COLORS[index],
      borderColor: BORDER_COLORS[index],
    });
  });

  return {
    labels: MESES,
    datasets,
  };
}

// Funciones de graficos de torta
export function generarDataSetPie(data) {
  const estados = data.map((item) => item.estado);
  const cantidad = data.map((item) => item.cantidad);

  return {
    labels: estados,
    datasets: [
      {
        label: "Cantidad",
        data: cantidad,
        backgroundColor: BACKGROUND_COLORS,
        borderColor: BORDER_COLORS,
      },
    ],
  };
}

// Funciones compartidas
export function generarListaAnios(data) {
  let anios = [];
  data.forEach((item) => {
    if (!anios.includes(item.anio)) {
      anios.push(item.anio);
    }
  });
  if (anios.length > CANT_MAX_ANIOS) {
    anios = anios.slice(anios.length - CANT_MAX_ANIOS, anios.length);
  }
  return anios;
}
