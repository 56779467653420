import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { MenuItem, Menu } from "semantic-ui-react";
import { getAniosDisponibles, getEstadoServiciosPorAnio, getEstadoServiciosPorMesYAnio } from "./serviceLizen";

ChartJS.register(ArcElement, Tooltip, Legend);

const ServicioLizenEstado = () => {
  const [dataSet, setDataSet] = useState(null);
  const [dataSetBar, setDataSetBar] = useState(null);
  const [activeItem, setActiveItem] = useState(new Date().getFullYear());
  const [aniosDisponibles, setAniosDisponibles] = useState([
    new Date().getFullYear(),
  ]);

  const handleItemClick = (e, { name }) => setActiveItem(name);

  useEffect(() => {
    async function fetchData() {
      setAniosDisponibles(await getAniosDisponibles());
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setDataSet(await getEstadoServiciosPorAnio(activeItem));
      setDataSetBar(await getEstadoServiciosPorMesYAnio(activeItem));
    }

    fetchData();
  }, [activeItem]);

  return dataSet && dataSetBar ? (
    <div>
      <Menu attached="top" tabular>
        {aniosDisponibles &&
          aniosDisponibles.map((anio) => (
            <MenuItem
              name={anio}
              active={activeItem === anio}
              onClick={handleItemClick}
            />
          ))}
      </Menu>{" "}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <Pie width={250} data={dataSet} />
        </div>
        <div style={{ width: "100%" }}>
          <Bar height={100} data={dataSetBar} />
        </div>
      </div>
    </div>
  ) : (
    <div>Cargando...</div>
  );
};
export default ServicioLizenEstado;
